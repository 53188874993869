import convert, { Unit } from 'convert';
import { CartFromApi } from '../types/cart';
import {
  DraftOrderFromApi,
  SmartOrder,
  SmartOrderAnalysisError,
  SmartOrderItemRow,
  SmartOrderStatusEnum,
  DraftOrderTypeEnum,
  SmartOrderValidationRules,
  SmartOrderDebugInfo,
  SmartOrderAssignee,
} from '../types/smart-orders';
import { getQuantity } from './quantity-convertor';
import { buildCompany } from './compnay-builder';
import { addMinutes, subMinutes } from 'date-fns';
import { groupBy } from 'lodash';
import { User, UserFromApi } from '../types/user';
import { convertKeysToCamelCase } from './case-convertor';
import { buildUser } from './user-builder';

export const buildSmartOrder = (
  soFromApi: DraftOrderFromApi,
  locale: Locale,
  newCustomerDelay: number,
  validationRules: SmartOrderValidationRules | undefined,
  debugMode = false,
): SmartOrder | undefined => {
  const metadata = soFromApi.metadata || {};
  if (!debugMode && metadata.is_smart_order_test) {
    return undefined;
  }
  const senderEmail = soFromApi.metadata?.smart_order_sender_email as string;

  return {
    senderEmail,
    id: soFromApi.id,
    orderId: soFromApi.order_id || undefined,
    status: metadata.smart_order_status as SmartOrderStatusEnum,
    displayId: soFromApi.display_id as string,
    externalId: soFromApi.cart?.external_id || undefined,
    buyerCompany: soFromApi.cart?.company
      ? buildCompany(soFromApi.cart?.company, newCustomerDelay, locale)
      : undefined,
    buyerEmail: (soFromApi.cart?.customer?.email as string) || senderEmail,
    errors: metadata.smart_order_errors as SmartOrderAnalysisError[],
    purchaseUrl: metadata.smart_order_purchase_order_url as string,
    createdAt: new Date(soFromApi.created_at),
    updatedAt: soFromApi.updated_at
      ? new Date(soFromApi.updated_at)
      : undefined,
    cart: soFromApi.cart!,
    order: soFromApi.order,
    items: buildLines(soFromApi.cart),
    expectedLineCount: metadata.expected_line_count as number,
    smartOrderType: soFromApi.type || DraftOrderTypeEnum.PURCHASE_ORDER,
    debugInfo: debugMode
      ? buildDebugInfo(soFromApi, validationRules)
      : undefined,
    assignee: buildAssignee(soFromApi.assignee),
  };
};

const buildLines = (cart?: CartFromApi): SmartOrderItemRow[] => {
  if (!cart?.items) {
    return [];
  }
  return (
    cart.items
      .map((item) => {
        const displayUnit = item.variant?.metadata?.display_unit as Unit;
        const storageUnit = item.variant?.metadata?.storage_unit as Unit;
        const decimal = !!displayUnit && !!storageUnit;
        return {
          id: item.id,
          rank: item.rank,
          reference: item.variant!.sku,
          title: item.title!,
          thumbnail: item.variant?.thumbnail ?? null,
          quantity:
            getQuantity({
              quantity: item.quantity,
              displayUnit,
              storageUnit,
              decimal,
            }) ?? 0,

          unitPrice: decimal
            ? convert(item.unit_price! / 100, displayUnit).to(storageUnit)
            : item.unit_price! / 100,
          totalPrice: item.subtotal ? item.subtotal / 100 : 0,
          displayUnit,
          storageUnit,
          decimal,
        };
      })
      .sort((a, b) => a.rank - b.rank) || []
  );
};

const buildAssignee = (
  assignee?: UserFromApi,
): SmartOrderAssignee | undefined => {
  if (!assignee) {
    return undefined;
  }
  const user = buildUser(assignee);
  return {
    ...user!,
    initials: `${assignee.first_name?.[0] || ''}${
      assignee.last_name?.[0] || ''
    }`.toUpperCase(),

    colorClass: stringToColour(assignee.email),
  };
};

const buildDebugInfo = (
  so: DraftOrderFromApi,
  validationRules: SmartOrderValidationRules | undefined,
): SmartOrderDebugInfo => {
  // Build Datadog url
  const documentId = so.metadata?.document_id as string;
  const poUrl = so.metadata?.smart_order_purchase_order_url as string;
  const from = subMinutes(new Date(so.created_at), 5);
  const to = addMinutes(from, 10);
  const logsUrl = `https://app.datadoghq.eu/logs?query=%40correlationId%3A${documentId}%20%40source%3A%28%22${encodeURIComponent(
    poUrl,
  )}%22%20OR%20parseEmail%29&from_ts=${from.getTime()}&to_ts=${to.getTime()}&live=false`;

  // Determine guilty errors
  const failureCauses = getFailureCauses(so, validationRules);

  return {
    logsUrl,
    failureCauses,
    technicalError: so.metadata?.technical_error,
    isTest: so.metadata?.is_smart_order_test === true,
  };
};

const getFailureCauses = (
  so: DraftOrderFromApi,
  validationRules: SmartOrderValidationRules | undefined,
): string[] => {
  const sendRules = new Set(validationRules?.sent);
  const toCompleteRules = new Set(validationRules?.requires_action);
  const soErrors = (so.metadata?.smart_order_errors ||
    []) as SmartOrderAnalysisError[];
  const fatalErrors = validationRules
    ? soErrors.filter(
        (err) => !sendRules.has(err.type) && !toCompleteRules.has(err.type),
      )
    : [];
  if (fatalErrors.length) {
    // Return the name of the errors with the count of each one
    return getErrorsAndCounts(fatalErrors);
  }

  const toCompleteErrors = validationRules
    ? soErrors.filter((err) => toCompleteRules.has(err.type))
    : [];
  return getErrorsAndCounts(toCompleteErrors);
};

const getErrorsAndCounts = (errors: SmartOrderAnalysisError[]): string[] => {
  return Object.entries(groupBy(errors, 'type')).map(([type, errors]) => {
    return `${type} x${errors.length}`;
  });
};

const stringToColour = (str: string) => {
  const colorClasses = [
    'text-slate-600 bg-slate-200',
    'text-stone-600 bg-stone-200',
    'text-red-600 bg-red-200',
    'text-orange-600 bg-orange-200',
    'text-amber-600 bg-amber-200',
    'text-yellow-600 bg-yellow-200',
    'text-lime-600 bg-lime-200',
    'text-green-600 bg-green-200',
    'text-emerald-600 bg-emerald-200',
    'text-teal-600 bg-teal-200',
    'text-cyan-600 bg-cyan-200',
    'text-sky-600 bg-sky-200',
    'text-blue-600 bg-blue-200',
    'text-indigo-600 bg-indigo-200',
    'text-violet-600 bg-violet-200',
    'text-purple-600 bg-purple-200',
    'text-fuchsia-600 bg-fuchsia-200',
    'text-pink-600 bg-pink-200',
    'text-rose-600 bg-rose-200',
  ];
  let hash = 0;
  str.split('').forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  return colorClasses[Math.abs(hash) % colorClasses.length];
};
