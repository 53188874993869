import { ReactNode, useState, useEffect, useCallback } from 'react';
import { Activity, ActivityTypes } from '../types/activity';
import { useCatalogTranslation } from './use-catalog-translations';
import { Check, MessageText, SendDiagonal, Xmark } from 'iconoir-react';
import { format } from 'date-fns';
import { useRouter } from 'next/navigation';
import { useTranslation } from 'react-i18next';

type ActivityContent = {
  icon: ReactNode;
  title: string;
  formattedDate: string;
  agentName: string;
  onClick: () => void;
};

export const useActivityContent = (activity: Activity) => {
  const router = useRouter();
  const { ct } = useCatalogTranslation('admin_homepage');
  const { i18n } = useTranslation();

  const [activityContent, setActivityContent] =
    useState<ActivityContent | null>(null);

  const formatDate = useCallback((date: string | Date) => {
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    // Check if date is today
    if (format(dateObj, 'yyyy-MM-dd') === format(today, 'yyyy-MM-dd')) {
      return `${ct('Homepage.activityTodayAt', "Aujourd'hui à")} ${format(
        dateObj,
        'HH:mm',
      )}`;
    }

    // Check if date is yesterday
    if (format(dateObj, 'yyyy-MM-dd') === format(yesterday, 'yyyy-MM-dd')) {
      return `${ct('Homepage.activityYesterdayAt', 'Hier à')} ${format(
        dateObj,
        'HH:mm',
      )}`;
    }

    // For any other date
    return `${format(dateObj, 'dd/MM/yyyy')} ${ct(
      'Homepage.activityAt',
      'à',
    )} ${format(dateObj, 'HH:mm')}`;
  }, []);

  const ACTIVITY_CONFIG = {
    [ActivityTypes.SMART_ORDER_RECEIVED]: {
      icon: <MessageText className="h-3 w-3" />,
      titleKey: 'Homepage.activityReceived',
      titleDefault: 'Commande #{{id}} : Reçue par Arthur',
      getPath: (activity: Activity) =>
        `/smartorder?displayId=${activity.smartOrderDisplayId}`,
    },
    [ActivityTypes.SMART_ORDER_FAILED]: {
      icon: <Xmark className="h-3 w-3" />,
      titleKey: 'Homepage.activityFailed',
      titleDefault: 'Commande #{{id}} : Échec de traitement',
      getPath: (activity: Activity) =>
        `/smartorder?displayId=${activity.smartOrderDisplayId}`,
    },
    [ActivityTypes.SMART_ORDER_SENT_TO_ERP]: {
      icon: <SendDiagonal className="h-3 w-3" />,
      titleKey: 'Homepage.activitySent',
      titleDefault: 'Commande #{{id}} : Envoyée à votre ERP',
      getPath: (activity: Activity) => `/orders/${activity.orderId}`,
    },
    [ActivityTypes.SMART_ORDER_TO_COMPLETE]: {
      icon: <Check className="h-3 w-3" />,
      titleKey: 'Homepage.activityRequiresAction',
      titleDefault: 'Commande #{{id}} : Informations manquantes détectées',
      getPath: (activity: Activity) =>
        activity.orderId
          ? `/orders/${activity.orderId}`
          : `/smartorder/${activity.draftOrderId}`,
    },
    [ActivityTypes.SMART_ORDER_COMPLETED]: {
      icon: <Check className="h-3 w-3" />,
      titleKey: 'Homepage.activityCompleted',
      titleDefault: 'Commande #{{id}} : Complétée manuellement',
      getPath: (activity: Activity) =>
        `/smartorder?displayId=${activity.smartOrderDisplayId}`,
    },
    [ActivityTypes.SMART_ORDER_CANCELED]: {
      icon: <Xmark className="h-3 w-3" />,
      titleKey: 'Homepage.activityCanceled',
      titleDefault: 'Commande #{{id}} : Annulée',
      getPath: () => `/smartorder`,
    },
  } as const;

  useEffect(() => {
    const config = ACTIVITY_CONFIG[activity.type];

    if (!config) return;

    setActivityContent({
      icon: config.icon,
      title: ct(config.titleKey, config.titleDefault, {
        id: activity.smartOrderDisplayId,
      }),
      formattedDate: formatDate(activity.createdAt),
      agentName: activity.agent ?? '-',
      onClick: () => router.push(config.getPath(activity)),
    });
  }, [activity, router, i18n.language]);

  return { activityContent } as const;
};
