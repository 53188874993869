import { Activity, ActivityFromApi } from '@/lib/types/activity';
import { UserFromApi } from '../types/user';

export const buildActivity = (activity: ActivityFromApi): Activity => {
  return {
    id: activity.id,
    type: activity.type,
    createdAt: new Date(activity.created_at),
    draftOrderId: activity.draft_order_id,
    smartOrderDisplayId: activity.draft_order?.display_id ?? null,
    orderId: activity.order_id ?? activity.draft_order?.order_id ?? null,
    agentId: activity.agent_id,
    agent: getAgent(activity.agent),
    isCanceled: activity.draft_order?.deleted_at !== null,
  };
};

function getAgent(agent?: UserFromApi | null): string | null {
  if (!agent) return null;
  return `${agent.first_name} ${agent.last_name}`;
}
